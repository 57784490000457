<template>
    <div class="container_process">
        <van-steps direction="vertical" active-color="#E3B467" :active="dataList.levelStatus">
            <van-step size="40">
                <h3>个人资料</h3>
                <h3>信息已确认</h3>
                <p>{{dataList.create_time}}</p>
            </van-step>
            <van-step>
                <h3 v-if="type==1">会员支付</h3>
                <h3 v-else> 运费支付</h3>
                <div class="payText_t">
                    <div class="left_t" v-if="type==1">会费：{{dataList.pay_at?dataList.pay_amount:tableData.union_member_fee}}/年</div>
                    <div class="left_t" v-else>运费：{{dataList.pay_at?dataList.pay_amount:tableData.shareholder_renew_shipping_fee}}/年</div>
                    <div v-if="dataList.pay_at" class="right_t">已完成</div>
                    <div v-else class="right_t" @click="payBtn">去支付</div>
                </div>
                <p>{{dataList.pay_at}}</p>
            </van-step>
            <van-step>
                <h3 :style="dataList.audit_state==3?'color:red':''" v-if="type==1">签约审核</h3>
                <h3 :style="dataList.audit_state==3?'color:red':''" v-else>延期审核</h3>
                <div style="color:rgb(227, 180, 103)" v-if="dataList.audit_state==1">延期审核中</div>
                <div style="color:rgb(227, 180, 103)" v-if="dataList.audit_state==2">延期已通过</div>
                <div style="color:red" v-if="dataList.audit_state==3">续费审核失败</div>
                <div style="color:red" v-if="dataList.audit_state==3">原因:{{dataList.audit_remark?dataList.audit_remark[0]:dataList.info_remark[0]}}</div>
                <p>{{dataList.audit_at}}</p>
            </van-step>
            <van-step>
                <h3 style="color:red" v-if="dataList.audit_state==3">已结束</h3>
                <h3 v-else>物料邮寄</h3>
                <div v-if="dataList.levelStatus==3&&!dataList.shipments">等待物料制作，物料发放</div>
                <div  v-if="dataList.shipments">已发货</div>
            </van-step>
        </van-steps>
    </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0
    };
  },
  props: {
    dataList: {
      type: Object
    },
    type:{
      type:String
    },
    tableData:{
      type:Object
    }
  },
  methods: {
    
    payBtn(){
      console.log(this.dataList)
      if(this.type!=1){
          this.$router.push(`/expenditure/vipUser/pay?type=1&id=${this.dataList.audit_id}`)
          
      }else{
        
        this.$router.push(`/expenditure/vipUser/pay?id=${this.dataList.audit_id}`)
      }
        
    }
  }
};
</script>
<style>
.van-step__circle {
  width: 30px;
  height: 30px;
  background-color: #e3b467;
}
.van-step__icon {
  width: 20px;
  height: 20px;
}
.van-icon {
  width: 20px;
  height: 20px;
  font-size: 30px;
}
@step-icon-size:24px .van-icon-checked:before {
  font-size: 20px !important;
}
.container_process {
  margin-left: 55px;
}
.van-step--vertical .van-step__line {
  width: 2px;
  background-color: #e3b467;
}
.van-step--vertical{
  line-height:44px
}
.van-step__icon,
.van-step__title {
  font-size: 30px;
  font-weight: 400;
}
.van-step__title {
  margin-left: 30px;
}
.van-steps__items {
  margin-left: 20px;
}
.payText_t {
  display: flex;
  line-height:64px
}
.payText_t .left_t {
  font-size: 30px;
  color: rgba(255, 66, 24, 1);
  margin-right:20px
}
.payText_t .right_t {
  width: 166px;
  height: 64px;
  background: #2f2b2a;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  text-align: center;
  line-height:64px;
  font-size: 30px;color:#fff
}
p{
  color:rgb(227, 180, 103)
}
</style>
